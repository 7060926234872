import React from 'react';
import { styled } from 'linaria/react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../../components/Theme';
import cartQuery from '../../../gql/queries/cartQuery.gql';
import HelloRetailProductRecomCheckout from '../../../blocks/HelloRetail/HelloRetailProductRecomCheckout';
import MaxWidth from '../../../components/Layout/MaxWidth';
import ButtonWithLoading from '../../../components/ui/Button';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useTracker } from '@jetshop/core/analytics/Analytics';

const Container = styled('aside')`
  border: 2px solid #eaeaea;

  /* ${theme.above.sm} {
    width: 100%;
  }
  ${theme.below.sm} {
    width: 100%;
  } */
  background-color: ${theme.colorScheme.white};
  height: 100%;
`;

const ProductImageWrapper = styled('div')`
  width: 150px;
  margin-right: 2rem;

  ${theme.below.md} {
    height: 4rem;
    width: 5rem;
  }
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Product = styled('div')`
  display: flex;
  height: 90px;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  /* margin: ${theme.space[2]}; */
  padding: 6px 24px 6px 24px; 

  ${theme.below.sm} {
    /* padding: 5px 10px;  */
    /* margin-bottom: 11px; */
  }
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  color: #50d934;
`;

const Header = styled('h3')`
  /* font-size: ${theme.fontSizes[2]}; */
  margin-bottom: ${theme.space[1]};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
`;

const ProductName = styled('p')`
  /* font-size: ${theme.fontSizes[0]}; */
  color: black;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  ${theme.below.sm} {
    font-size: 12px;
  }
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: ${theme.fontSizes[0]};
`;

const CheckoutContainer = styled('div')`
  padding: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  position: sticky;
  z-index: 100;

  ${theme.below.lg} {
    border-top: 1px solid #eaeaea;
    bottom: 0;
    padding: 15px 0;
  }
  ${theme.below.md} {
    border-top: 1px solid #eaeaea;
    bottom: 0;
    padding: 10px 0;
  }
`;

const CheckoutButton = styled(ButtonWithLoading)`
  margin: 0.5rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    ${theme.colorScheme.addtocartswipeangle},
    ${theme.colorScheme.addtocartlight},
    ${theme.colorScheme.addtocartdark}
  );
  color: ${theme.colorScheme.addtocarttext};
  border: none;
  border-radius: 8px !important;
  padding: 12px 24px !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  svg path {
    fill: ${theme.colorScheme.addtocarttext};
  }
  :hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border: none !important;
    color: ${theme.colorScheme.addtocarttext} !important;
    svg path {
      fill: ${theme.colorScheme.addtocarttext} !important;
    }
  }

  /* Idle streak: Moves continuously left to right */
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(${theme.colorScheme.addtocartswipe}, 0) 10%,
      rgba(${theme.colorScheme.addtocartswipe}, 0.5) 50%,
      rgba(${theme.colorScheme.addtocartswipe}, 0) 90%
    );
    filter: blur(8px);
    transform: skewX(-20deg);
    animation: streak 13s linear infinite;
  }

  /* Hover streak: Bursts from center and expands outwards */
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    height: 30%;
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  :hover::after {
    animation: ripple 1s forwards;
  }

  /* Keyframes for the hover ripple effect */
  @keyframes ripple {
    0% {
      width: 0%;
      height: 30%;
      opacity: 1;
    }
    100% {
      width: 300%;
      height: 300%;
      opacity: 0;
    }
  }

  /* Keyframes for the idle streak */
  @keyframes streak {
    0% {
      left: -100%;
    }
    23.08% {
      /* Streak moves for 3s out of 13s total */
      left: 150%;
    }
    100% {
      left: 150%;
    }
  }
`;
const ContinueButton = styled('button')`
  margin: 0.5rem;
  text-align: center;
  background-color: black;
  color: white;
  font-family: 'Poppins';
  font-style: normal;

  border: 1px solid #000000;
  border-radius: 8px !important;
  padding: 12px 24px !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  :hover {
    background-color: white;
    color: black;
  }
`;

const RecomContainer = styled(MaxWidth)`
  padding: 0px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
`;

const ProductToast = ({
  product,
  cart,
  dismiss,
  selectedVariation,
  quantity,
  notificationId,
  error,
  loading
}) => {
  const track = useTracker();
  const image = selectedVariation?.images[0] || product.images[0];
  const recomNamePurchased = { value: 'upsell_step' };
  const titlePurchased = { value: t('Others have also bought') };
  const productNumbers = [product.articleNumber];

  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image.url}
                // sizes={80}
                aspect={'1:1'}
                alt={image.alt}
                quality={80}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            <ProductName>{product.name}</ProductName>
            {error ? (
              <Header>{t('Failed adding to bag')}</Header>
            ) : quantity === 1 ? (
              <Header>{t('Added to bag')}</Header>
            ) : (
              <Header>
                {t.rich('Added {quantity} items to bag', { quantity })}
              </Header>
            )}

            {error && (
              <Error>
                {t('An error occurred. Details:')}
                <ul>
                  {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                    error.graphQLErrors.map(({ message, locations, path }) => (
                      <li key={message}>{t(message)}</li>
                    ))
                  ) : (
                    <li>{t(error.message)}</li>
                  )}
                </ul>
              </Error>
            )}
          </ProductDetails>
        </Product>
        <RecomContainer>
          <HelloRetailProductRecomCheckout
            recomName={recomNamePurchased}
            title={titlePurchased}
            productNumbers={productNumbers}
            notificationId={notificationId}
            dismiss={dismiss}
          />
        </RecomContainer>
        <CheckoutContainer>
          <ContinueButton onClick={() => dismiss(notificationId)}>
            {t('Continue shopping')}
          </ContinueButton>

          {cart && cart.externalCheckoutUrl && (
            <ChannelContext.Consumer>
              {({ selectedChannel }) => (
                <CheckoutButton
                  href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                  onClick={() => {
                    track(trackCartCheckoutEvent({ cart }));
                  }}
                >
                  {t('To checkout')}
                </CheckoutButton>
              )}
            </ChannelContext.Consumer>
          )}
        </CheckoutContainer>
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
