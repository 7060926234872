import React from 'react';
import { styled } from 'linaria/react';

import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import { Price } from '@jetshop/ui/Price';
import { useChannelSettings } from '@jetshop/core/hooks/Channels/useChannelSettings';
import { Currency } from '@jetshop/ui/Price/Currency';

import { theme } from '../Theme';
import Button from '../ui/Button';
import CartItem from './CartItem';
import FreeShipping from './FreeShipping';

import cartQuery from '../../gql/queries/cartQuery.gql';

const Flyout = styled('div')`
  background: white;
  color: ${theme.colorScheme.black};
  ${theme.above.sm} {
    position: absolute;
    right: 0;
    top: 3.5rem;
    width: 25rem;
    min-width: 19rem;
    max-width: 100%;
    height: calc(100vh - 7rem);
    z-index: 999999;
  }
`;

const LightText = styled('span')`
  color: #828282;
  font-size: 0.875rem;
  font-weight: normal;
`;

const Header = styled('header')`
  text-align: center;
  h2 {
    font-weight: 600;
  }
  ${LightText} {
    display: block;
  }
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
`;

const ItemCount = ({ count }) => (
  <LightText>
    {t(
      `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
      { count }
    )}
  </LightText>
);

const CartItems = styled('section')`
  background: #fafafa;
  padding: 1rem;
  max-height: calc(100vh - 290px);
  ${theme.above.sm} {
    max-height: calc(100vh - 6rem - 306px);
  }
  overflow: auto;
`;

const Summary = styled('section')`
  background: #fff;
  padding: 1rem;
  ${theme.below.md} {
    position: absolute;
    bottom: 0px;
  }
  width: 100%;

  h2 {
    font-weight: bold;
    font-size: 1.2em;
  }

  .discounts {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }

  .cart-total {
    ${theme.below.md} {
      bottom: 104px;
      position: absolute;
    }

    padding-top: 1em;
    border-top: 1px solid ${theme.colorScheme.lightgrey};

    > * + * {
      margin-top: 0.5em;
    }

    .shipping,
    .total,
    .vat {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total {
      font-size: 1.25rem;
      font-weight: 600;
      color: ${theme.colorScheme.black};
      .price {
        margin-left: 1rem;
      }
    }
  }
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled(Button)`
  width: 100%;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    ${theme.colorScheme.addtocartswipeangle},
    ${theme.colorScheme.addtocartlight},
    ${theme.colorScheme.addtocartdark}
  );
  color: ${theme.colorScheme.addtocarttext};
  border: none;
  border-radius: 8px !important;
  padding: 12px 24px !important;
  ${theme.below.md} {
    margin-bottom: 1rem;
  }
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  svg path {
    fill: ${theme.colorScheme.addtocarttext};
  }
  :hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border: none !important;
    color: ${theme.colorScheme.addtocarttext};
    svg path {
      fill: ${theme.colorScheme.addtocarttext};
    }
  }

  /* Idle streak: Moves continuously left to right */
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(${theme.colorScheme.addtocartswipe}, 0) 10%,
      rgba(${theme.colorScheme.addtocartswipe}, 0.5) 50%,
      rgba(${theme.colorScheme.addtocartswipe}, 0) 90%
    );
    filter: blur(8px);
    transform: skewX(-20deg);
    animation: streak 13s linear infinite;
  }

  /* Hover streak: Bursts from center and expands outwards */
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    height: 30%;
    background: radial-gradient(
      ellipse at center,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  :hover::after {
    animation: ripple 1s forwards;
  }

  /* Keyframes for the hover ripple effect */
  @keyframes ripple {
    0% {
      width: 0%;
      height: 30%;
      opacity: 1;
    }
    100% {
      width: 300%;
      height: 300%;
      opacity: 0;
    }
  }

  /* Keyframes for the idle streak */
  @keyframes streak {
    0% {
      left: -100%;
    }
    23.08% {
      /* Streak moves for 3s out of 13s total */
      left: 150%;
    }
    100% {
      left: 150%;
    }
  }
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const itemCount = result?.data?.cart?.totalQuantity ?? 0;
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const { pricesIncVat } = useChannelSettings();

  if (items.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em' }}>{t('No items in cart.')}</div>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <Header>
        <h2>{t('Your cart')}</h2>
        <ItemCount count={itemCount} />
      </Header>
      <Summary>
        {checkoutUrl && (
          <Checkout
            data-testid="checkout-button"
            href={checkoutUrl}
            onClick={() => {
              track(trackCartCheckoutEvent({ cart: result.data.cart }));
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
      </Summary>
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems>
      <Summary>
        {discounts.length > 0 && (
          <div className="discounts">
            <h2>{t('Cart total')}</h2>
            <Flex>
              <label>{t('Order value')}</label>
              <Price price={result.data.cart.productPreviousTotal} />
            </Flex>
            <h2 style={{ marginTop: '1em' }}>{t('Discounts')}</h2>
            {discounts.map(discount => {
              return (
                <Flex key={discount.name} style={{ marginTop: '1em' }}>
                  <label>{discount.name}</label>
                  <Price price={discount.value} style={{ display: 'inline' }} />
                </Flex>
              );
            })}
          </div>
        )}

        <div className="cart-total">
          <FreeShipping
            className="shipping"
            cartTotal={result.data.cart.productTotal}
          />

          <div className="total">
            <label>
              {pricesIncVat ? t('Total incl. VAT') : t('Total excl. VAT')}
            </label>
            <Price price={result.data.cart.productTotal} />
          </div>

          <VAT
            className="vat"
            total={result.data.cart.productTotal}
            pricesIncVat={pricesIncVat}
          />
        </div>
      </Summary>
    </Flyout>
  );
};

function VAT({ total, pricesIncVat, ...rest }) {
  return (
    <div {...rest}>
      <label>{pricesIncVat ? t('of which VAT') : t('+ VAT')}</label>
      <div>
        <Currency value={total.vat} />
      </div>
    </div>
  );
}

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <Above breakpoint="md">
          {matches =>
            matches ? (
              <FlyoutTarget id="cart-flyout">
                {flyout => (
                  <CartFlyoutView modal={flyout} result={result} {...props} />
                )}
              </FlyoutTarget>
            ) : (
              <DrawerTarget id="cart-drawer">
                {drawer => (
                  <Drawer isOpen={drawer.isOpen} right>
                    <CartFlyoutView modal={drawer} result={result} {...props} />
                  </Drawer>
                )}
              </DrawerTarget>
            )
          }
        </Above>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
